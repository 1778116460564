<template>
  <div>
    <div class="taskBox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: 'order' }">工单</el-breadcrumb-item>
        <el-breadcrumb-item>新建工单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="item">
        <div class="title">位置</div>
        <el-autocomplete popper-class="my-autocomplete" v-model="state" :fetch-suggestions="querySearch" placeholder="请选择点位或者手动输入" @select="handleSelect" @input="handleInput" style="width:369px">
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
        </el-autocomplete>
      </div>
      <div class="item">
        <div class="title">人员</div>
        <el-select style="width:369px" v-model="createProblemPar.trace_user_ids" multiple placeholder="请选择">
          <el-option v-for="item in memberList" :key="item.id" :label="item.name" :value="item.user_id">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="title">备注</div>
        <el-input type="textarea" rows='3' placeholder="请输入内容" v-model="createProblemPar.remark" style="width:369px;" show-word-limit>
        </el-input>
      </div>

      <div class="item" style="margin-left:80px;width:500px">
        <el-upload :action="postUrl" list-type="picture-card" :auto-upload="true" :limit="9" :before-upload="beforeAvatarUpload" :data='postData' :file-list="fileList" :on-success="handleSuccess">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                <i class="el-icon-download"></i>
              </span> -->
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
      <div style="margin-left:237px">
        <el-button type="primary" plain @click="goLast">取消</el-button>
        <el-button type="primary" @click='queryCreateProblem'>确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { organization, site, files, problem, obsUrl } from '../../utils/api';
import qs from 'qs';
export default {
  name:'newOrder',
  data() {
    return {
      // 人员列表
      memberList: [],
      // 点位列表
      siteList: [],
      restaurants: [],
      state: '',
      createProblemPar: {
        site_id: 0,
        location: {
          address: null
        },
        files: [],
        trace_user_ids: [],
        // user_id: [],
        remark: ''
      },
      postUrl: obsUrl,
      postData: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileListMain: [],
      fileList: [],
      imgUrl: ''
    };
  },

  components: {},

  computed: {},
  created() {
    this.cloneCreateProblemPar = JSON.parse(
      JSON.stringify(this.createProblemPar)
    );
    this.getLIstSite();
    this.getLIstMember();
  },
  methods: {
    async getLIstMember() {
      const res = await this.$http.get(organization.listMember);
      this.memberList = res.data;
    },
    async getLIstSite() {
      const res = await this.$http.get(site.listSite);
      this.siteList = res.data;
    },
    querySearch(queryString, cb) {
      var restaurants = this.siteList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.state = item.name;
      this.createProblemPar.site_id = item.id;
      this.createProblemPar.location.address = null;
    },
    handleInput(value) {
      this.createProblemPar.site_id = 0;
      this.createProblemPar.location.address = value;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        return this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      let par = {
        content_type: file.type,
        module: 'problem'
      };
      const res = await this.$http.get(
        files.huaweiCloudObsPolicySignature + '?' + qs.stringify(par)
      );
      this.postData = res.data;
      this.imgUrl = this.postUrl + '/' + res.data.key;
      this.createProblemPar.files.push(this.imgUrl)
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleRemove(file) {
      let find = this.fileList.findIndex(item => {
        return item.uid == file.uid;
      });
      this.fileList.splice(find, 1);
      this.createProblemPar.files.splice(find, 1);
    },
    handleSuccess(res, file, fileList) {
      file.imgUrl = this.imgUrl;
      this.fileList = fileList;
    },
    goLast() {
      this.$router.go(-1);
    },
    async queryCreateProblem() {
      // this.fileList.forEach(element => {
      //   this.createProblemPar.files.push(element.imgUrl);
      // });
      const res = await this.$http.post(problem.createProblem, this.createProblemPar);
      if (res.code == 0) {
        this.$message.success('创建工单成功');
        this.state = ''
        this.createProblemPar=JSON.parse(JSON.stringify(this.cloneCreateProblemPar))
        this.fileList.splice(0,this.fileList.length)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.taskBox {
  width: 1168px;
  height: 56px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  line-height: 56px;
  padding-left: 16px;
  margin-bottom: 8px;
  .el-breadcrumb-item {
    line-height: 56px;
    .el-breadcrumb__inner {
      line-height: 56px;
    }
  }
}
.content {
  background-color: #fff;
  width: 1168px;
  height: 703px;
  padding: 16px;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .title {
      width: 80px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 14px;
    }
  }
}
.el-breadcrumb__inner,
.el-breadcrumb__inner.is-link {
  line-height: 56px !important;
}
span {
  line-height: 56px;
}
</style>
